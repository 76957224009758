import styled from "styled-components";

export const BottomCard = styled("div")`
  padding: 48px 10% 48px;
  width: 100%;
  min-height: 530px;
  height: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;

  @media (max-width: 992px) {
    & {
      height: 468px;
      padding: 40px 5%;
      min-height: unset;
    }
  }
  @media (max-width: 768px) {
    & {
    }
  }
  @media (max-width: 450px) {
    & {
    }
  }

  .footerArea {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 16px;
    padding: 4px;
    gap: 16px;
    position: fixed;
    bottom: 3%;
    right: 5%;
    .title {
      span {
        font-family: "Carter One", cursive;
      }
    }
    .links {
      display: flex;
      gap: 16px;

      a {
        color: black;
        font-weight: 400;
        text-decoration: underline;
      }
    }

    @media (max-width: 450px) {
      & {
        font-size: 11px;
        gap: 8px;
      }
      .links {
        gap: 8px;
      }
    }

    @media (max-width: 390px) {
      & {
        gap: 4px;
      }
      .links {
        gap: 4px;
      }
    }
  }
`;
