import React from "react";
import { Button, Col, Input, notification, Row } from "antd";
import { BsArrowRightCircle } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import { signin } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { SignupContent } from "./index.style";
import { decode } from "js-base64";
import { useParams } from "react-router-dom";
import { sendEvent } from "../../services/amplitude";
import { AMPLITUDE_EVENTS } from "../../utils/text";

const SignupModal = ({ setStep }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { url } = useParams();

  const { loading } = useSelector((state) => state?.auth);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSignupLogin = ({ email }) => {
    const loginData = {
      email,
      articleURL: decode(url),
    };
    dispatch(signin({ loginData, setStep, api }));
    sendEvent(AMPLITUDE_EVENTS.ENTERED_EMAIL);
  };

  return (
    <SignupContent>
      {contextHolder}
      <h3>Access this content anytime, anywhere </h3>
      <form onSubmit={handleSubmit(onSignupLogin)}>
        <label htmlFor='Email' className='emailLabel labelName'>
          Email
        </label>

        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 13 }}>
            <Input.Group compact>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <Input
                    className='emailInput'
                    {...field}
                    placeholder='your@email.com'
                  />
                )}
              />

              <Button htmlType='submit' type='submit'>
                {loading ? <LoadingOutlined /> : <BsArrowRightCircle />}
              </Button>
            </Input.Group>
            {errors.email && <p className='error'>{errors.email.message}</p>}
          </Col>
          {/* <label className="faceId" style={{ paddingLeft: "5px" }} htmlFor="">
            Face-ID
          </label>
          <Col xs={{ span: 24 }} lg={{ span: 4 }}>
            <Fingerprint>
              <span>
                <img src="/icons/face-id.svg" alt="" />
              </span>
              <span>
                <img src="/icons/fingerprint.svg" alt="" />
              </span>
            </Fingerprint>
          </Col> */}
        </Row>
        {/* <label className='or'>Or</label> */}
        {/*<br />
        <label htmlFor='PhoneNumber' className='phoneNumberLabel labelName'>
          Phone Number
        </label>
        <Row gutter={16}>
          <Col xs={{ span: 24 }}>
            <Input.Group compact>
              <Input style={{ width: "80%" }} placeholder='Phone Number' />
              <Button>
                <BsArrowRightCircle />
              </Button>
            </Input.Group>
          </Col>
        </Row> */}
        {/* <div className='paymentArea'>
          {getDeviceType() === "iOS" || getDeviceType() === "MacBook" ? (
            <PayBtn>
              <img src='/icons/ios-pay.svg' alt='' />
            </PayBtn>
          ) : (
            <PayBtn>
              <img className='googleLink' src='/icons/link-icon.svg' alt='' />
              <img src='/icons/g-pay.svg' alt='' />
            </PayBtn>
          )}
        </div> */}
      </form>
    </SignupContent>
  );
};

export default SignupModal;
