  import styled from 'styled-components'

export const BottomCard = styled('div')`
  padding: 3em 10% 3em;
  width: 100%;
  height: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  text-align: center;
  h3 {
    font-size: 2.25rem;
    font-weight: 900 !important;
    font-family: 'Catamaran', sans-serif !important;
    margin-bottom: 1em;
  }
  .subscribBtn {
    width: 428px;
    height: 88px;
    border-radius: 10px;
    font-weight: 900 !important;
    font-size: 1.875rem;
    font-family: 'Catamaran';
    background: #ffffff;
    cursor: pointer;
    transition: 200ms all ease-in-out;
    &:hover {
      border: 2px solid #351596 !important;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    }
  }
  p {
    margin-top: 1em;
    font-size: 0.9em;
    a {
      transition: 200ms all ease-in-out;
      border-bottom: 1px solid #000000;
      font-size: 1em;
      color: #000000;
      &:hover {
        border-bottom: 1px solid #351596;
        color: #351596;
      }
    }
  }
  h4 {
    font-size: 1.6em;
    font-family: 'Catamaran', sans-serif;
    font-weight: 900 !important;
  }
  @media (max-width: 992px) {
    & {
      padding: 2em 5%;
      height: 435px;
      h3 {
        font-size: 1.6em;
        margin-bottom: 0.5em;
      }
      p {
        font-size: 1em;
      }
      .subscribBtn {
        width: 230px;
        height: 46px;
        font-size: 1.2em;
      }
    }
  }
`
