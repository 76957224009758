import { Button } from 'antd'
import styled from 'styled-components'

export const CustomButton = styled(Button)`
  width: 428px;
  height: 88px;
  display: inline-flex;
  align-items: center;
  border: none !important;
  div {
    font-size: 30px;
  }

  .buttonTitle {
    display: flex;
    align-items: center;
    justify-content: start;

    .lockIcon {
      font-size: 72px;
      margin: 0 1rem 0 1.5rem;
    }
  }
  border-radius: 10px;
  font-weight: 900 !important;
  font-size: 1.875rem;
  font-family: 'Catamaran';
  transition: 200ms all ease-in-out;
  div {
    font-weight: 900 !important;
  }
  img {
    width: 47px;
    margin: 0 1em;
  }
  &:hover {
    background: #351596 !important;
    box-shadow: 6px 6px 20px rgba(66, 9, 238, 0.25);
    border: none !important;
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    /* stroke: #4209ee; */
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .checkmark {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 1px 0px 0px #4209ee;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes {
    scale {
      0%,
      100% {
        transform: none;
      }
      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #4209ee;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 230px;
      height: 46px;
      div {
        font-size: 1rem;
      }
      img {
        width: 20px;
        margin: 0 1em;
      }
      .checkmark {
        width: 25px;
        height: 25px;
      }
    }
  }
`
