import styled from "styled-components";

export const PaymentContent = styled("div")`
  h3 {
    font-size: 2.25rem;
    font-weight: 900 !important;
    font-family: "Catamaran", sans-serif !important;
    margin-bottom: 1em;
  }

  .whitespin {
    font-size: 16px;
  }
  .boldLabel {
    font-weight: 700;
    font-size: 16px;
  }
  .nameField {
    font-family: "Helvetica Neue", sans-serif !important;
    font-weight: 400;
  }

  .cardComponent {
    padding-right: 3px !important;
  }

  .whitespin > svg {
    color: white !important;
  }

  .bottom {
    color: red !important;
    margin-top: 2px;
    padding: 0px;
  }

  .cardComponent {
    display: flex;
    justify-content: start;
    align-items: start;
    position: relative;
    gap: 2rem;
    border: 2px solid rgb(0, 0, 0) !important;
    background: rgb(230, 230, 230);
    border-radius: 4px;
    padding: 0 0 0 0.75rem;
    overflow: hidden;

    @media (max-width: 768px) {
      & {
        gap: 0rem;
      }
    }

    .StripeElement {
      width: 100%;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      .__PrivateStripeElement {
        width: 100%;
        position: relative;

        top: 2px;
      }
    }
  }

  .cardButton {
    width: 100% !important;
    background: #4209ee !important;
    border-radius: 22px;
    margin-top: 10px;
    font-size: 18px;
    height: 46px !important;
    color: white;
    svg {
      position: relative;
      bottom: 1px;
    }
    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
  .stripeBrand {
    font-size: 1rem;
    display: flex;
    margin-top: 5px;
    gap: 0.25rem;
    bottom: -28px;
    left: 12px;
    p {
      color: #878686 !important;
      font-family: "Catamaran", sans-serif !important;
      margin: 0;
    }

    img {
    }
  }
  /* h3 {
    font-size: 36px;
    font-weight: 900 !important;
    font-family: "Catamaran", sans-serif !important;
    margin-bottom: 0.75rem;
  }
  p {
    font-size: 1em;
  } */
  form {
    .cardLabel {
      font-size: 1.875rem !important;
      font-family: "Catamaran", sans-serif !important;
      margin-bottom: 0.5rem;
    }
    .or {
      margin: 2rem 0 1rem 0;
      /* margin: 1rem 0 1rem !important; */
      font-size: 1.875rem !important;
      font-family: "Catamaran", sans-serif !important;
      display: inline-flex !important;
    }
  }
  label {
    margin: 0em 0 1em;
    font-size: 1.5em;
    display: inline-flex;
    color: #000000;
  }

  .ant-input-group {
    border: 2px solid #000000 !important;
    overflow: hidden;
    border-radius: 4px;
    background: #e6e6e6;
    margin: 0em 0 2rem;
    /* font-family: Catamaran, sans-serif; */
    input {
      color: black !important;
      font-family: "Helvetica Neue";
      font-weight: 400 !important;
      font-size: 16px !important;
      &::placeholder {
        color: #878686;
        font-size: 16px !important;
        font-weight: 400 !important;
        // letter-spacing: 0.025em !important;
        font-family: "Helvetica Neue";
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #e6e6e6 inset !important;
      -webkit-text-fill-color: #878686;
    }

    input::selection {
      color: #ffffff;
      -webkit-text-fill-color: #ffffff;
    }

    &:hover {
      outline: 2.5px solid rgba(66, 9, 238, 0.5) !important;
      /* box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25); */
    }

    @media (max-width: 450px) {
      input {
        font-family: "Helvetica Neue";
        font-weight: 400 !important;
        font-size: 16px !important;
      }
    }
  }

  .error {
    position: absolute;
    color: red;
    margin: 0;
    top: 40%;
  }

  .ant-picker,
  .ant-input,
  .ant-input-number {
    height: 58px;
    background: #e6e6e6;
    width: 100%;
    font-size: 20px;
    border: none;
    &:focus {
      border: none !important;
    }
    .ant-picker-suffix {
      display: none;
    }

    &:hover {
      border-color: #e6e6e6 !important;
    }
  }
  .ant-input-number-focused,
  .ant-picker-focused {
    border-color: none !important;
    box-shadow: none;
  }
  .ant-btn {
    background: rgb(230, 230, 230);
    border: none;
    width: 10%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    .anticon {
      line-height: 0 !important;
    }
    svg {
      line-height: 0 !important;
      font-size: 30px;
      color: #00000090;
    }
    &:hover {
      svg {
        font-size: 30px;
        color: #4209ee;
      }
    }
  }
  input {
    font-family: "Helvetica Neue";
    font-size: 16px !important;
    &::placeholder {
      font-family: "Helvetica Neue";
      font-size: 16px !important;
      color: #00000090;
    }
    height: 54px;
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    font-family: "Helvetica Neue" !important;
    font-weight: 400;
  }
  .ant-picker {
    input {
      height: 43px;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .paymentArea {
    display: flex;
    justify-content: start;
    // align-items: center;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    & {
      form {
        .cardLabel {
          font-size: 1.25rem !important;
        }
        .or {
          font-size: 1.25rem !important;
        }
      }

      .cardButton {
        /* padding-right: 8px; */
        width: 100% !important;
        background: #4209ee !important;
        border-radius: 22px;
        font-size: 18px;
        margin: auto;
        margin-top: 10px;
        height: 46px !important;
        color: white;
      }
      h3 {
        font-size: 1.6em;
        margin-bottom: 0.5em;
      }
      p {
        font-size: 1em;
      }
      label {
        margin: 0em 0 0.5em;
        font-size: 1em;
      }
      input {
        width: 100%;
        height: 46px;
        font-size: 1em;
      }
    }
  }
  @media (max-width: 450px) {
    .ant-picker,
    .ant-input,
    .ant-input-number {
      font-size: 16px;
    }
  }
`;
export const PayBtn = styled("a")`
  width: 220px;
  height: 58px;
  background: #000000;
  border-radius: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 0.85;
  transition: 200ms;

  img {
    width: 90px;
  }
  .googleLink {
    width: 35px;
    margin-right: 1em;
  }
  &:hover {
    opacity: 1;
  }
  @media (max-width: 992px) {
    & {
      width: 160px;
      height: 46px;
      img {
        width: 70px;
      }
      .googleLink {
        width: 22px;
        margin-right: 1em;
      }
    }
  }
  @media (max-width: 768) {
    & {
    }
  }
`;
