export const basePath =
  process.env.REACT_APP_BASE_PATH || "http://127.0.0.1:8000/api/v1/";

export const APIurls = {
  // auth
  signin: "auth",
  paymentToken: "auth/details",
  verifyOTP: "auth/verify",
  userProfile: "profile",
  payment: "billing/purchase",
  publisherDetails: "billing/details",
  clientSecret: "billing/secret",
  exposeprofile: "expose/profile",
  paymentSecret: "stripe/payment-intent",
  transaction: "stripe/transaction-check",
  cardInformationCheck: "/auth/card-check",
};
