import React, { useState } from "react";
import OtpInput from "react18-input-otp";

const OtpCode = ({ otp, setOtp }) => {
  return (
    <div>
      <OtpInput
        className="otpInput"
        value={otp}
        onChange={setOtp}
        inputType="number"
        numInputs={6}
        renderInput={(props) => <input {...props} />}
        autoComplete="one-time-code"
        isInputNum={true}
        containerStyle={{
          justifyContent: "space-between",
          width: "100%",
        }}
      />
    </div>
  );
};

export default OtpCode;
