export const TEXTS = {
  DEFAULT_ACTA_PROMPT_TEXT: "Or just buy this article",
  DEFAULT_ACTA_BUTTON_TEXT: "Read it for",
}

export const AMPLITUDE_EVENTS = {
  CLICKED_ACTA_BUTTON: "CLICKED ACTA BUTTON",
  ENTERED_EMAIL: "ENTERED EMAIL",
  ENTERED_CC: "ENTERED CC",
  ENTERED_AUTH: "ENTERED AUTH",
  CONFIRMED_OTP: "CONFIRMED OTP",
  PAID: "PAID",
  CLICKED_GP_AP: "CLICKED GP / AP",
}