import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../screens/userScreens/home";
import GeneralLayout from "../layout";
// import Signup from "../screens/auth/signup";
// import Login from "../screens/auth/login";
// import AddPaymentMethod from "../screens/auth/addPaymentMethod";
// import PaymentMethod from "../screens/auth/paymentMethod";
// import OTPBox from "../screens/auth/OTPBox";
// import AddPaymentSuccess from "../screens/auth/paymentSuccess";
const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<GeneralLayout />}>
          <Route path='/:id/:url' element={<Home />} />
        </Route>
        {/* <Route path='/signin' element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path='/add-payment' element={<AddPaymentMethod />} />
        <Route path='/payment-method' element={<PaymentMethod />} />
        <Route path='/otp' element={<OTPBox />} />
        <Route path='/success' element={<AddPaymentSuccess />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
