import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendPaymentToken } from "../redux/authSlice";
function useFirstLogin(userProfile, cardTokenDetails, setStep, api, tokenSent) {
  const dispatch = useDispatch();
  const [isLogin, setFirstLogin] = useState(false);
  useMemo(() => {
    let userinfo = localStorage.getItem("authUser");

    let userob;
    if (userinfo) {
      userob = JSON.parse(userinfo);
    }
    const authsignup = userProfile?.authsignup;
    const username = userProfile?.name;
    const stripeinfo = userob?.stripe?.customer_id;
    if (
      (authsignup !== false || !username) &&
      (!stripeinfo || (stripeinfo && !userob?.stripe?.card)) &&
      cardTokenDetails?.paymentCard?.brand !== "" &&
      !userob?.stripe?.customer_id
    ) {
      if (!userob?.token) {
        if (!tokenSent) {
          localStorage.setItem("isFirst", userProfile?.id);
          setFirstLogin(true);
          dispatch(
            sendPaymentToken({
              data: {
                userId: userProfile?.id,
                name: cardTokenDetails?.cardHolderName,
                cardToken: cardTokenDetails?.id,
                email: userob.email,
                sendEmailOtp: false,
                firstVisit: true,
              },
              setStep,
              api,
            }),
          );
        }
      }
    }
  }, [cardTokenDetails, tokenSent]);

  return isLogin;
}
export default useFirstLogin;
