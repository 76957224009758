import { APIurls } from "./constants";
import apiService from ".";

export const ApiRequests = {
  // auth
  signin: async (data) => await apiService.post(APIurls.signin, data),

  sendPaymentToken: async (data) =>
    await apiService.post(APIurls.paymentToken, data),

  verifyOTP: async (data) => await apiService.post(APIurls.verifyOTP, data),
  cardinfocheck: async (data) =>
    await apiService.post(APIurls.cardInformationCheck, data),
  makePayment: async (data) => await apiService.post(APIurls.payment, data),

  updateUserProfile: async (data) =>
    await apiService.patch(APIurls.userProfile, data),

  getExposeUserprofile: async (data) =>
    await apiService.get(`${APIurls.exposeprofile}/${data.id}`),
  getPaymentIntentSecurityInfo: async (data) =>
    await apiService.post(APIurls.paymentSecret, data),

  getUserProfile: async () => await apiService.get(APIurls.userProfile),

  getPublisherDetails: async (id, data) =>
    await apiService.post(APIurls.publisherDetails + "/" + id, data),

  getClientSecret: async (data) =>
    await apiService.post(APIurls.clientSecret, data),
  transactionCheck: async (data) => await apiService.post(APIurls.transaction,data)
};
