import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { getPublisherDetails } from "./publisherSlice";
// import { toast } from "react-toastify";
import { decode } from "js-base64";
import { confirmPayment } from "../utils/helper";
import { sendEvent } from "../services/amplitude";
import { AMPLITUDE_EVENTS } from "../utils/text";

// sign in
export const signin = createAsyncThunk(
  "auth/signin",
  async (
    { loginData, setStep, forWhat, api, resetTimer, setDisableResend },
    { dispatch, rejectWithValue, getState },
  ) => {
    const state = getState();
    try {
      const response = await ApiRequests.signin(loginData);
      if (response?.status === 200) {
        localStorage.setItem(
          "authUser",
          JSON.stringify(response?.data?.RESPONSE),
        ); //setting user
        if (forWhat && forWhat === "resendOTP") {
          resetTimer();
          setDisableResend(true);
          api["success"]({
            message: "",
            className: "cutomNotification",
            description: "OTP sent successfully",
            duration: 2,
          });
        }
        setStep("confirmOtp");
      }
      if (response.status == 202) {
        if (response.data) {
          localStorage.setItem(
            "authUser",
            response?.data?.RESPONSE &&
              JSON.stringify(response?.data?.RESPONSE),
          );
          await dispatch(getExposeProfile(setStep));
        }
        const userinfo = localStorage.getItem("authUser");
        let userob;
        if (userinfo) {
          userob = JSON.parse(userinfo);
        }
        if (userob?.stripe?.customer_id) {
          setStep("paymentSignup");
        } else {
          setStep("addPayment");
        }
      }
      if (response?.status === 201) {
        localStorage.setItem(
          "authUser",
          JSON.stringify(response?.data?.RESPONSE),
        ); //setting user
        setStep("addPayment");
      }

      return response?.data?.RESPONSE;
    } catch (error) {
      if (error?.response?.status === 403) {
        setStep("addPayment");
        return state?.auth?.userProfile;
      }
      if (error?.response?.status === 400) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: "Invalid Email Address!",
          duration: 2,
        });
        return state?.auth?.userProfile;
      }
      if (error?.response?.status === 429) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: "Too many requests. Please wait 1 minute",
          duration: 2,
        });
        return state?.auth?.userProfile;
      }
      return state?.auth?.userProfile;

      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  },
);

// get client secret
export const getClientSecret = createAsyncThunk(
  "auth/getClientSecret",
  async (
    { data, ev, stripe, setStep, setIsScrolledDown, url },
    { dispatch, rejectWithValue, getState },
  ) => {
    const state = getState();
    try {
      const baseURL = new URL(decode(url))?.origin;

      const response = await ApiRequests.getClientSecret(data);
      if (response?.status === 200) {
        confirmPayment(
          response?.data?.RESPONSE?.clientSecret,
          ev,
          stripe,
          setStep,
          setIsScrolledDown,
          dispatch,
          getPublisherDetails,
          state,
          baseURL,
          url,
          data?.userId,
        );
      }

      return response?.data?.RESPONSE?.clientSecret;
    } catch (error) {
      return state?.auth?.userProfile;
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  },
);
// get expose user profile
export const getExposeProfile = createAsyncThunk(
  "auth/getExposeProfile",
  async ({ setStep }, { dispatch, rejectWithValue, getState }) => {
    try {
      const userprofile = localStorage.getItem("authUser");
      const state = getState();
      const response = await ApiRequests.getExposeUserprofile(
        userprofile && JSON.parse(userprofile),
      );
      if (response.status === 200) {
        if (state.auth.userProfile.isfirst === false) {
          const data = { isFirst: false, ...response?.data.RESPONSE };
          return data;
        }

        localStorage.setItem(
          "authUser",
          JSON.stringify(response?.data?.RESPONSE),
        );
      }
      return response?.data?.RESPONSE;
    } catch (Error) {
      console.log(Error);
    }
  },
);

// get user profile
export const getUserProfile = createAsyncThunk(
  "auth/getUserProfile",
  async ({ setStep }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getUserProfile();

      if (response?.status === 200) {
        localStorage.setItem(
          "authUser",
          JSON.stringify(response?.data?.RESPONSE),
        ); //setting user
      }
      return response?.data?.RESPONSE;
    } catch (error) {
      if (
        error?.response?.data?.CODE === "UNAUTHORIZED" ||
        error?.response?.data?.CODE === "SESSION_EXPIRED" ||
        error?.response?.data?.CODE === "INVALID_TOKEN"
      ) {
        dispatch(logout());
        setStep("signIn");
      }
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  },
);

// update user profile
export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async ({ data, setStep, api }, { dispatch, rejectWithValue, getState }) => {
    const state = getState();
    try {
      const isFirstLogin = state?.auth?.userProfile?.isfirst;
      if (isFirstLogin || state.auth.userProfile.authsignup === false) {
        data = {
          ...data,
          isFirst: true,
          userId:
            localStorage.getItem("authUser") &&
            JSON.parse(localStorage.getItem("authUser")).id,
        };
      }
      const response = await ApiRequests.updateUserProfile(data);
      if (response?.status === 200) {
        if (data?.email && !isFirstLogin) {
          setStep("confirmOtp");
          localStorage.removeItem("authUser");
        } else {
          if (isFirstLogin || state.auth.userProfile.authsignup === false) {
            dispatch(getExposeProfile({ setStep }));
          } else {
            dispatch(getUserProfile({ setStep }));
          }
        }
      } else if (response.status === 202) {
        if (isFirstLogin || !state.auth.userProfile.token) {
          dispatch(getExposeProfile({ setStep }));
        } else {
          dispatch(getUserProfile({ setStep }));
        }
      } else {
      }
      return response?.data?.RESPONSE;
    } catch (error) {
      if (error?.response?.status === 400) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: error?.response?.data?.ERROR.message,
          duration: 2,
        });
      }
      if (error?.response?.status === 429) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: "Too many requests. Please wait 1 minute",
          duration: 2,
        });
      }
      rejectWithValue(error?.response?.data);
    }
  },
);

// send Payment Token
export const sendPaymentToken = createAsyncThunk(
  "auth/sendPaymentToken",
  async ({ data, setStep, api }, { dispatch, rejectWithValue, getState }) => {
    const state = getState();
    try {
      const { firstVisit, ...newData } = data;
      if (state.auth?.userProfile?.isFirst) {
        newData.sendEmailOtp = false;
      }
      const response = await ApiRequests.sendPaymentToken(newData);
      if (response?.status === 200) {
        if (data?.phone) {
          const oldUserData = JSON.parse(localStorage.getItem("authUser"));
          const newData = { ...oldUserData, phone: data?.phone };
          localStorage.setItem("authUser", JSON.stringify(newData));
        }

        if (!state?.auth?.userProfile?.token && firstVisit) {
          await dispatch(getExposeProfile({ setStep }));
          setStep("paymentSuccess");
        } else {
          setStep("confirmOtp");
        }
        dispatch(setTokenSent(true));
      }
      return response?.data?.RESPONSE;
    } catch (error) {
      if (
        error?.response?.data?.ERROR?.message ===
        "This phone number is already registered!"
      ) {
        dispatch(setTokenSent(true));
      }
      if (
        error?.response?.data?.ERROR?.message ===
        "This email is already registered!"
      ) {
        dispatch(setTokenSent(true));
        dispatch(setNewEmail(undefined));
      }
      if (error?.response?.status === 400) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: error?.response?.data?.ERROR?.message,
          duration: 2,
        });

        return state?.auth?.userProfile;
      }
      if (error?.response?.status === 429) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: "Too many requests. Please wait 1 minute",
          duration: 2,
        });
        return state?.auth?.userProfile;
      }
      if (error?.response?.status === 409) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: "Payment Method Already Exists!",
          duration: 2,
        });
        return state?.auth?.userProfile;
      }

      return state?.auth?.userProfile;
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  },
);

// get user details
export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (
    { data, ev, stripe, setStep, setIsScrolledDown, url },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      const response = await ApiRequests.sendPaymentToken(data);
      if (response?.status === 200) {
        dispatch(
          getClientSecret({
            data: { articleURL: decode(url), userId: data?.userId },
            ev,
            stripe,
            setStep,
            setIsScrolledDown,
            url,
          }),
        );
      }
      return response?.data?.RESPONSE;
    } catch (error) {
      console.log(error);
    }
  },
);

// send otp
export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async (
    { data, setStep, setIsScrolledDown, url, api },
    { dispatch, rejectWithValue, getState },
  ) => {
    const state = getState();
    try {
      const response = await ApiRequests.verifyOTP(data);

      if (response?.status === 200) {
        localStorage.setItem("verifyotp", true);
        localStorage.setItem(
          "authUser",
          JSON.stringify(response?.data?.RESPONSE),
        ); //setting user
        dispatch(getUserProfile({ setStep }));
        console.log(state.publisher);
        dispatch(
          getPublisherDetails({
            publisherId: state?.publisher?.publisherDetails?.id,
            data: {
              articleURL: decode(url),
              userId: state?.auth?.userProfile?.id,
            },
          }),
        );
        if (state?.publisher?.publisherDetails?.purchased) {
          setIsScrolledDown(false);
        } else {
          setStep("paymentSuccess");
        }
      }
      return response?.data?.RESPONSE;
    } catch (error) {
      if (error?.response?.status === 400 || error?.response?.status === 404) {
        api["error"]({
          message: "",
          className: "cutomNotification",
          description: "Invalid OTP Code!",
          duration: 2,
        });
        return state?.auth?.userProfile;
      }
      return state?.auth?.userProfile;
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  },
);

// check card detail information

export const cardInfoCheck = createAsyncThunk(
  "card/check",
  async (
    { data, setCardInfoCheck },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      const result = await ApiRequests.cardinfocheck(data);
      if (result.data.status_code == 200) {
        setCardInfoCheck(true);
      } else {
        setCardInfoCheck(false);
      }
    } catch (error) {
      setCardInfoCheck(false);
    }
  },
);

// send Payment Token
export const makePayment = createAsyncThunk(
  "auth/makePayment",
  async (
    { data, setStep, setIsScrolledDown, url },
    { dispatch, rejectWithValue, getState },
  ) => {
    const state = getState();
    try {
      const isFirstLogin = state?.auth?.userProfile?.isfirst;
      if (isFirstLogin) {
        data = {
          ...data,
          isFirst: true,
          userId:
            localStorage.getItem("authUser") &&
            JSON.parse(localStorage.getItem("authUser")).id,
        };
      }

      const response = await ApiRequests.makePayment(data);
      const baseURL = new URL(decode(url))?.origin;

      if (response?.status === 200) {
        sendEvent(AMPLITUDE_EVENTS.PAID);
        setStep("signIn");
        setIsScrolledDown(false);
        localStorage.setItem("payId:", state?.auth?.userProfile?.id);
        setTimeout(async () => {
          await dispatch(
            getPublisherDetails({
              publisherId: state?.publisher?.publisherDetails?.id,
              data: {
                articleURL: decode(url),
                isFirst: isFirstLogin,
                userId:
                  localStorage.getItem("authUser") &&
                  JSON.parse(localStorage.getItem("authUser")).id,
              },
            }),
          );
          var currentUrl = document.referrer;
          if (currentUrl) {
            window.parent.postMessage(
              {
                purchased: state?.publisher?.publisherDetails?.purchased,
                postData: state?.publisher?.publisherDetails?.postData,
              },
              currentUrl,
            );
          }
        }, 2000);
      }
      return response?.data;
    } catch (error) {
      console.log(error);
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  },
);

const initialState = {
  loading: false,
  pagLoading: false,
  updateLoading: false,
  editDetails: false,
  cardTokenDetails: {},
  userProfile: {},
  currentStep: null,
  tokenSent: false,
  clientSecret: null,
  newEmail: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokenSent(state, action) {
      state.tokenSent = action.payload;
    },
    setNewEmail(state, action) {
      state.newEmail = action.payload;
    },
    setEditDetails(state, action) {
      state.editDetails = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },

    saveCardTokenDetails(state, action) {
      state.cardTokenDetails = action.payload;
    },

    logout(state, action) {
      localStorage.removeItem("authUser");
      state.userProfile = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && action.payload.authsignup === false) {
          state.userProfile.isfirst = false;
        }
        state.userProfile = { ...state.userProfile, ...action.payload };
      })
      .addCase(signin.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getClientSecret.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientSecret.fulfilled, (state, action) => {
        state.loading = false;
        state.clientSecret = action.payload;
      })
      .addCase(getClientSecret.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.pagLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.pagLoading = false;

        state.userProfile = action.payload;
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.loading = false;
        state.pagLoading = false;
      })

      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.errmsg = "";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfile = { ...state.userProfile, ...action.payload };
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        console.log(action.payload);
      })

      .addCase(verifyOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfile = action.payload;
      })
      .addCase(verifyOTP.rejected, (state) => {
        state.loading = false;
      })

      .addCase(sendPaymentToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendPaymentToken.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfile = { ...state.userProfile, ...action.payload };
      })
      .addCase(sendPaymentToken.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getExposeProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExposeProfile.fulfilled, (state, action) => {
        state.loading = false;

        if (state.userProfile.authSignup === false) {
          state.userProfile = { ...state.userProfile, ...action.payload };
        } else {
          state.userProfile = {
            ...state.userProfile,
            ...action.payload,
            isfirst: true,
          };
        }
      })
      .addCase(getExposeProfile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(makePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(makePayment.fulfilled, (state, action) => {
        state.loading = false;
        // state.userProfile = action.payload;
      })
      .addCase(makePayment.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default authSlice.reducer;
export const {
  logout,
  saveCardTokenDetails,
  setCurrentStep,
  setEditDetails,
  setTokenSent,
  setNewEmail,
} = authSlice.actions;
