import { useState, useEffect } from "react";
export const useCountryCode = () => {
  const [code, setCountryCode] = useState(undefined);

  useEffect(() => {
    const apiRequest = async () => {
      const ipRequestResponse = await fetch("https://ipapi.co/json/");
      const responseData = await ipRequestResponse.json();
      const code = responseData.country_code;
      setCountryCode(code);
    };
    apiRequest();
  }, []);

  return code;
};
