import styled from "styled-components";

export const FinalizeContent = styled("div")`
  .paymentCard {
    border: 1px solid #000000 !important;
    border-radius: 12px;
    padding: 0 1rem;
    max-width: 555px;
    height: 105px;
    margin-bottom: 2em;
    color: #525254;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;

    .labelHedings {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      gap: 0.25rem;
      width: 7rem;
      label {
        font-size: 1.5rem;
        font-weight: 700 !important;
      }
    }

    .paymentData {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 85%;
      border-radius: 12px;
      width: 21.25rem;
      border: 1px solid transparent;
      box-shadow: transparent 0px 0px 0px 3px;
      gap: 0.85rem;
      overflow: hidden;
      padding: 0 0.75rem;

      .email {
        font-size: 20px;
        position: relative;
        overflow: hidden;
      }
      .paymentCardDetails {
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 1.25rem;
        img {
          width: 50px;
          height: 25px;
          filter: grayscale(1);
        }
      }
      .editMobile {
        display: none;
      }
    }

    .paymentEdit {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 85%;
      border-radius: 12px;
      width: 21.25rem;
      border: 1px solid #4209ee;
      box-shadow: rgba(66, 9, 238, 0.3) 0px 0px 0px 3px;
      gap: 32px;
      padding: 0 0.75rem;
      position: relative;

      .divider {
        height: 3px;
        background: rgba(66, 9, 238, 0.3);
        width: 100%;
        position: absolute;
        left: 0;
      }

      .editEmail {
        position: relative;
        bottom: -6px;
        input {
          font-size: 20px;
          border: none;
          margin: 0;
          padding: 0;

          color: black !important;
          font-family: sans-serif !important;
          &::placeholder {
            color: #878686;
            font-family: sans-serif !important;
          }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
          -webkit-text-fill-color: #878686;
        }

        input::selection {
          color: #ffffff;
          -webkit-text-fill-color: #ffffff;
        }
      }

      .editPaymentCardDetails {
        position: relative;
        top: -8px;
      }
    }

    .actionButtons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 4rem;
      gap: 0.75rem;

      span {
        height: 1.5rem;
      }
      .edit {
        user-select: none;
        text-decoration: underline;
        cursor: pointer;
        position: relative;
        top: -0.375rem;
        font-size: 1.25rem;
      }
      .close {
        font-size: 1.25rem;
        cursor: pointer;
        user-select: none;
        position: relative;
        top: 0.25rem;
      }
      button {
        margin: 0.5rem 0 0 0;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        font-weight: normal !important;
        color: #4209ee;
        text-decoration: underline;
        font-size: 1.25rem;
      }
    }

    .stripeBrand {
      font-size: 1rem;
      color: #878686 !important;
      position: absolute;
      bottom: -1.5rem;
      left: 0.5rem;

      stron {
        color: #878686;
      }
    }
  }

  h3 {
    font-size: 2.25rem;
    margin-bottom: 2rem;
    font-weight: 900 !important;
    font-family: Catamaran, sans-serif !important;
  }

  @media (max-width: 992px) {
    & {
      h3 {
        font-size: 1.6em;
        margin-bottom: 0.5em;
      }
      a {
        font-size: 1em;
      }
      .paymentCard {
        height: 120px;
        flex-direction: column;
        border: none !important;
        padding: 0;
        margin-bottom: 1rem;

        .editEmail {
          bottom: -5px !important;
        }
        .paymentCardDetails {
          justify-content: space-between !important;
          width: 100% !important;

          .editMobile {
            display: block;
            user-select: none;
            text-decoration: underline;
            cursor: pointer;
            position: relative;
            top: -2px;
            left: -4px;
            font-size: 1.25rem !important;
          }
        }
        .paymentData {
          width: 100% !important;
          border: 1px solid black;
          .email {
            bottom: 2.25px;
          }
        }
        .paymentEdit {
          width: 100% !important;
        }
        .labelHedings {
          display: none;
        }
        .actionButtons {
          flex-direction: row;
          height: auto;
          position: absolute;
          right: 5px;
          bottom: -25px;

          .edit {
            display: none;
          }
          .close {
            font-size: 1.25rem !important;
            top: 0.25rem !important ;
          }
          button {
            font-size: 1.5rem;
          }
        }
        span {
          font-size: 1rem !important;
        }
        /* .item {
        .label {
          font-size: 1rem;
          font-weight: 500 !important;
        }
      } */
        .cardField {
          label {
            height: max-content;
            margin: 0;
          }
        }
        .credit-card-input {
          font-size: 0.75rem;
          height: 25px !important;
        }
      }
    }
  }

  @media (max-width: 768) {
    .paymentCard {
      height: 120px;
      span {
        font-size: 0.875rem !important;
      }
    }
  }

  @media (max-width: 425px) {
    .paymentCard {
      .paymentData {
        .email {
          font-size: 16px;
          bottom: 5px;
        }
        .paymentCardDetails {
          font-size: 16px !important;
          .editMobile {
            font-size: 16px !important;
          }
        }
      }
      .paymentEdit {
        .editEmail {
          input {
            font-size: 16px;
          }
        }
      }

      .actionButtons {
        bottom: -14px;
        .close {
          font-size: 16px !important;
        }
        button {
          font-size: 16px;
        }
      }
    }
  }
`;

export const UserpaymentCard = styled("div")`
  margin-bottom: 2em;
  img {
    margin-right: 1em;
  }
  h5 {
    font-size: 1.5em;
    font-weight: 700 !important;
    margin-bottom: 0.5em;
  }
  strong,
  span {
    font-size: 1.3em;
    font-weight: 600 !important;
  }
  strong {
    font-weight: 700 !important;
  }
  @media (max-width: 992px) {
    & {
      margin-bottom: 1em;
      img {
        margin-right: 0.5em;
        width: 35px;
      }
      h5 {
        font-size: 1.2em;
      }
      strong,
      span {
        font-size: 1em;
      }
    }
  }
`;
