import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CloseBtn, OTPContent, OtpModal } from "./index.style";
import { Col, Form, notification, Row } from "antd";
import OtpCode from "../inputs/otpInput";
import { useDispatch, useSelector } from "react-redux";
import { setTokenSent, signin, verifyOTP } from "../../redux/authSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { useTimer } from "@mzaleski/use-timer";
import { useParams } from "react-router-dom";
import { sendEvent } from "../../services/amplitude";
import { AMPLITUDE_EVENTS } from "../../utils/text";

const OTPModal = ({
  setStep,
  setIsScrolledDown,
  userPhoneNumber,
  setUserPhoneNumber,
}) => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { userProfile, loading, currentStep } = useSelector(
    (state) => state?.auth,
  );
  const [disableResend, setDisableResend] = useState(true);
  const { id, url } = useParams();

  const { secondsRemaining, resetTimer } = useTimer(60, false, () =>
    setDisableResend(false),
  );

  useEffect(() => {
    if (otp.length === 6) {
      sendEvent(AMPLITUDE_EVENTS.CONFIRMED_OTP);
      dispatch(
        verifyOTP({
          data: {
            userId: userProfile?.id,
            code: otp,
            phoneNumber: userPhoneNumber,
          },
          setStep,
          setIsScrolledDown,
          url,
          api,
        }),
      );
    }
  }, [otp, dispatch]);

  const maskPhoneNumber = (str) => {
    const phonePattern = /^\+[1-9]\d{1,14}$/;
    if (phonePattern.test(str)) {
      const countryCode = str.slice(0, 3);
      const lastThree = str.slice(-3);
      const maskLength = str.length - 6;
      const maskedChars = "•".repeat(maskLength);
      return `${countryCode}${maskedChars}${lastThree}`;
    } else {
      return "";
    }
  };

  return (
    <OTPContent>
      {contextHolder}
      <h3>Confirm Code</h3>
      <OtpModal>
        <Form onFinish={""} className="otpForm">
          <CloseBtn
            onClick={() => {
              currentStep ? setStep(currentStep) : setStep("signIn");
              setUserPhoneNumber(null);
            }}>
            <img src="/icons/closeIcon.svg" alt="close" />
          </CloseBtn>
          {/* {userPhoneNumber ? (
            <label
              htmlFor="email"
              className="otpOn"
              style={{ display: "inline-block" }}>
              Enter code sent to <b>{maskPhoneNumber(userPhoneNumber)}</b> to
              securely use your saved information
            </label>
          ) : */}
          {/* ( */}
          <label
            htmlFor="email"
            className="otpOn"
            style={{ display: "inline-block" }}>
            Enter code sent to{" "}
            <b>
              {userProfile?.otpOn?.split("•")?.[0]}
              {"•••"}
              {
                userProfile?.otpOn?.split("•")?.[
                  userProfile?.otpOn?.split("•")?.length - 1
                ]
              }{" "}
            </b>
            to securely use your saved information
          </label>
          {/* ) */}
          {/* } */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="number">
                <OtpCode otp={otp} setOtp={setOtp} />
              </Form.Item>
            </Col>
          </Row>
          <div>
            <span
              className={`resendCode ${disableResend && "disableResendCode"}`}
              onClick={() => {
                if (!disableResend) {
                  dispatch(
                    signin({
                      loginData: {
                        email: userProfile?.email,
                        phone: userPhoneNumber ?? null,
                      },
                      forWhat: "resendOTP",
                      api,
                      resetTimer,
                      setDisableResend,
                    }),
                  );
                }
              }}>
              Resend code
            </span>
            {loading && <LoadingOutlined className="loadingIcon" />}
          </div>
          <span className={`timer ${!disableResend && "hideTimer"}`}>
            Resend Code in: {secondsRemaining}{" "}
          </span>
        </Form>
      </OtpModal>
    </OTPContent>
  );
};

export default OTPModal;
