import styled from "styled-components";
import { CustomButton } from "../button/index.style";
import { adjustColorBrightness } from "../../utils/helper";
import { PayBtn } from "../payment/index.style";

export const PaymentButton = styled(CustomButton)`
  .successLoader {
    width: 100%;
    font-size: 3rem;
    margin: 0.75rem 0 0 0;
  }
  &:hover {
    background: ${(prop) => {
      const darkenedColor = adjustColorBrightness(prop?.hovercolor, -20); // Darken the color by reducing brightness
      return darkenedColor;
    }} !important;
    box-shadow: 6px 6px 20px rgba(66, 9, 238, 0.25);
    border: none !important;
  }
  .checkmark {
    box-shadow: inset 1px 0px 0px ${(prop) => prop?.bgcolor};
  }

  .buttonTitle {
    display: flex;
    align-items: center;
    justify-content: start;

    .lockIcon {
      font-size: 72px;
      margin: 0 1rem 0 1.5rem;
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px ${(prop) => prop?.bgcolor};
    }
  }

  @media (max-width: 992px) {
    & {
      height: 60px;
    }
    .successLoader {
      font-size: 1.5rem;
      margin: 0.25rem 0 0 0;
    }
    .buttonTitle {
      .lockIcon {
        font-size: 36px;
        margin: 0 -0.5rem 0 1.5rem;
      }
    }
  }
`;

export const StripePayBtn = styled(PayBtn)`
  background: transparent;
  div {
    width: 100%;
  }
`;
export const PaySection = styled.div`
  width: 220px;
  font-family: Catamaran;

  @media (max-width: 992px) {
    & {
      width: 170px;
    }
  }
  p {
    font-size: 15px;
    color: #000000;
    margin-top: 5px;
  }
`;

export const PayWithCardDisabled = styled(PayBtn)`
  border-radius: 5px;
  background: #838383;
  height: 42px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  cursor: not-allowed;
  opacity: 1;
  span {
    height: 50%;
    width: 2px;
    background: #fff;
  }
  img {
    height: 20px;
    width: 80px;
  }

  &:hover {
    background: #838383;
    color: #fff;
  }
`;
