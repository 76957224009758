import "./App.css";
import "antd/dist/antd.min.css";
import AllRoutes from "./routes";

import { useEffect } from "react";

function App() {
  useEffect(() => {
    const triggerID = "cta_button";

    const dataLayerEvent = {
      event: "acta_paywall_button",
      triggerID,
    };

    // Push the event to the dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerEvent);
  }, []);
  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
