import { Link } from "react-router-dom";
import styled from "styled-components";

export const SignupContent = styled("div")`
  h3 {
    font-size: 2.25rem;
    margin-bottom: 0.75rem;
    font-weight: 900 !important;
    font-family: Catamaran, sans-serif !important;
  }
  p {
    margin-top: 1em;
    font-size: 1em;
  }
  .or {
    margin: 0 0 0.5em;
    font-size: 1.875rem;
    display: inline-flex;
  }
  .emailLabel {
    margin: 1em 0 0.25em 0;
    font-size: 1.875rem;
    display: inline-flex;
  }
  .phoneNumberLabel {
    margin: 1em 0;
    font-size: 1.5em;
    display: inline-flex;
  }
  .faceId {
    display: none;
  }
  .error {
    color: red;
    position: absolute;
    bottom: -6px;
  }
  .ant-input-group {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000000 !important;
    overflow: hidden;
    border-radius: 4px;
    background: #e6e6e6;
    margin: 0 0 2.5em;
    &:hover {
      outline: 2.5px solid rgba(66, 9, 238, 0.5) !important;
      /* box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25); */
    }
  }
  input {
    height: 58px;
    background: #e6e6e6;
    // border: 2px solid #000000;
    font-size: 20px;
    &::placeholder {
      color: #00000090;
    }
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e6e6e6 inset !important;
  }
  .ant-input {
    &:hover {
      border-color: transparent !important;
    }
    &:focus {
      border-color: transparent !important;
      box-shadow: none;
      border-right-width: 1px;
      outline: 0;
    }
  }
  .ant-btn {
    background: rgb(230, 230, 230);
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
    .anticon {
      line-height: 0 !important;
    }
    svg {
      line-height: 0 !important;
      font-size: 30px;
      color: #00000090;
    }
    &:hover {
      svg {
        font-size: 30px;
        color: #4209ee;
      }
    }
  }
  .paymentArea {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  @media (max-width: 992px) {
    & {
      h3 {
        font-size: 1.6em;
        margin-bottom: 0.5em;
      }
      p {
        font-size: 1em;
      }
      label {
        margin: 0em 0 0.5em;
        font-size: 1em;
      }
      .ant-input-group {
        width: 100%;
      }
      .faceId {
        display: block;
      }
      .labelName {
        font-size: 1.25rem;
      }
      .or {
        font-size: 1.25rem;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      input {
        width: 100%;
      }
    }
  }
  @media (max-width: 450px) {
    & {
      input {
        font-size: 16px;
      }
    }
  }
`;

export const PayBtn = styled(Link)`
  width: 220px;
  height: 58px;
  background: #000000;
  border-radius: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
  opacity: 0.85;
  img {
    width: 90px;
  }
  .googleLink {
    width: 35px;
    margin-right: 1em;
  }
  &:hover {
    opacity: 1;
  }
  @media (max-width: 992px) {
    & {
      width: 160px;
      height: 46px;
      img {
        width: 70px;
      }
      .googleLink {
        width: 22px;
        margin-right: 1em;
      }
    }
  }
`;

export const Fingerprint = styled("div")`
  width: 170px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  height: 58px;
  background: #e6e6e6;
  border: 2px solid #000000;
  span {
    cursor: pointer;
    img {
      width: 50px;
      height: auto;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 160px;
      height: 46px;
      margin-bottom: 1em;
      span {
        img {
          width: 30px;
        }
      }
    }
  }
`;
