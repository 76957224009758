import styled from "styled-components";

export const BgColor = styled("div")`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 5.95%,
    #838383 169.95%
  );
  //   mix-blend-mode: normal;
`;
