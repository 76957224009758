import React from "react";
import ReactDOM from "react-dom/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "intl-tel-input/build/css/intlTelInput.css";

Bugsnag.start({
  apiKey: process.env.React_APP_DEBUG_KEY,
  plugins: [new BugsnagPluginReact()],
});

BugsnagPerformance.start({ apiKey: process.env.React_APP_DEBUG_KEY });
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const stripeKey = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(stripeKey);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
      {/* </React.StrictMode> */}
    </Provider>
  </ErrorBoundary>,
);

reportWebVitals();
