import { useState, useEffect } from "react";
export const useDesktopCheck = () => {
  const [documentWidth, setDocumentWidth] = useState(0);

  useEffect(() => {
    const measureDocumentWidth = () => {
      const width = Math.max(
        document.documentElement.scrollWidth,
        document.body.scrollWidth,
      );
      setDocumentWidth(width);
    };

    // Measure the document width when the component mounts
    measureDocumentWidth();

    // Optionally, you can add a resize listener if you need to update on window resize
    window.addEventListener("resize", measureDocumentWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", measureDocumentWidth);
    };
  }, []);
  return documentWidth > 600;
};
