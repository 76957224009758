import styled from "styled-components";

export const OTPContent = styled("div")`
  h3 {
    font-size: 2.25rem;
    font-weight: 900 !important;
    font-family: "Catamaran", sans-serif !important;
    margin-bottom: 1em;
  }
  p {
    font-size: 1em;
  }
  .otpForm {
    width: 100% !important;
  }
  label {
    margin: 0em 0 1em;
    font-size: 1.5em;
    display: inline-flex;
    color: #000000;
  }
  input {
    width: 57px !important;
    border-radius: 12px;
    height: 81px;
    background: #e6e6e6;
    border: 2px solid #000000;
    font-size: 1.75rem;
    &::placeholder {
      color: #00000090;
    }
  }
  .paymentArea {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  @media (max-width: 992px) {
    & {
      h3 {
        font-size: 22px;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 720px) {
    & {
    }
  }

  @media (max-width: 590px) {
    & {
      input {
        width: 40px !important;
        height: 50px;
        font-size: 18px;
        border-radius: 6px;
      }
    }
  }

  @media (max-width: 425px) {
    & {
      input {
        width: 28px !important;
        height: 38px;
        font-size: 22px;
        padding: 0 !important;
        border-radius: 4px;
        border-width: 2px;
      }
    }
  }

  @media (max-width: 390px) {
    & {
      input {
        width: 28px !important;
        height: 38px;
        padding: 0 !important;
        font-size: 16px;
        border-radius: 4px;
        border-width: 2px;
      }
    }
  }
`;

export const OtpModal = styled("div")`
  max-width: 640px;
  min-height: 320px;
  height: auto;
  border: 1px solid #000000;
  padding: 1em 4em;
  position: relative;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    label {
      font-size: 30px;
      font-weight: 400;
      color: #000000;
      line-height: 1;
    }
    .resendCode {
      cursor: pointer;
      border-bottom: 1px solid #4209ee;
      color: #4209ee;
      font-size: 30px;
      div {
        button {
          display: none;
        }
      }
    }
    .disableResendCode {
      cursor: not-allowed;
      color: rgba(66, 9, 238, 0.25);
      border-bottom: 1px solid rgba(66, 9, 238, 0.25);
    }
    .timer {
      color: #4209ee;
    }
    .hideTimer {
      display: none;
    }
    .loadingIcon {
      color: #4209ee;
      font-size: 24px;
      margin-left: 0.75rem;
      position: relative;
      top: 0.125rem;
    }
  }
  @media (max-width: 720px) {
    form {
      label {
        font-size: 24px;
      }
      .resendCode {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 590px) {
    & {
      height: 250px;
      padding: 32px;
      form {
        label {
          font-size: 16px;
        }
        .resendCode {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 425px) {
    & {
      padding: 16px;
    }
  }
`;
export const CloseBtn = styled("span")`
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 1.9em;
  cursor: pointer;

  @media (max-width: 590px) {
    & {
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
