import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { decode } from "js-base64";

import { sendToPublisherWebsite } from "./handleRedirection";
import { getPublisherDetails } from "../../redux/publisherSlice";
import { PaymentType, PaymentMethod } from "./paymentCconfig";
import { useDesktopCheck } from "../../hooks/useDesktopCheck";
import { useCountryCode } from "../../hooks/useCountryCode";
import { useCheckCountryAllow } from "../../hooks/useCheckCountryAllow";
import { ApiRequests } from "../../services/ApiRequest";

const AliPay = ({
  price,
  money,
  user,
  article,
  publisher,
  setStep,
  setIsScrolledDown,
  screen,
}) => {
  const [interval, setPollingInterval] = useState(undefined);
  const [secret, setSecretInformation] = useState(null);
  const dispatch = useDispatch();
  const publisherState = useSelector((state) => state.publisher);

  const stripe = useStripe();
  const elements = useElements();
  const isDeskTop = useDesktopCheck();
  const code = useCountryCode();
  const checkCountryFlag = useCheckCountryAllow(code);

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [interval]);

  if (!checkCountryFlag) return;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    let client_secret;
    if (!secret) {
      try {
        const tokenInfo = await ApiRequests.getPaymentIntentSecurityInfo({
          price,
          money,
          user,
          publisher,
          article: decode(article),
          method: PaymentMethod.alipay,
        });
        setSecretInformation(tokenInfo?.data?.data?.client_secret);
        client_secret = tokenInfo?.data?.data?.client_secret;
      } catch (error) {
        console.log(error.message);
      }
    } else {
      client_secret = secret;
    }

    if (!client_secret) return;

    const { error, paymentIntent } = await stripe.confirmAlipayPayment(
      client_secret,
      {
        return_url: `${process.env.REACT_APP_BASE_PATH}/stripe/payment-confirms`,
      },
      { handleActions: false },
    );

    if (error) return;

    const gatewayRedirectLink =
      paymentIntent.next_action.alipay_handle_redirect.url;
    sendToPublisherWebsite(PaymentType.alipay, gatewayRedirectLink);

    const intervalId = setInterval(async () => {
      try {
        const result = await ApiRequests.transactionCheck({
          article: decode(article),
          user,
        });
        setStep("signIn");
        setIsScrolledDown(false);
        localStorage.setItem("payId:", user);
        setTimeout(() => {
          dispatch(
            getPublisherDetails({
              publisherId: publisher,
              data: { articleURL: decode(article), userId: user },
            }),
          );

          window.parent.postMessage(
            {
              purchased: publisherState?.publisherDetails?.purchased,
              postData: publisherState?.publisherDetails?.postData,
            },
            "*",
          );
        }, 2000);
        clearInterval(intervalId);
      } catch (error) {
        console.log(error);
      }
    }, 7000);
    setPollingInterval(intervalId);
  };

  const formInLineStyle = isDeskTop
    ? {
        position: "absolute",
        left: "225px",
        top: screen !== "finalize" ? "205px" : "218px",
      }
    : {
        position: "absolute",
        left: "134px",
        top: screen !== "finalize" ? "310px" : "320px",
      };
  const buttonInlineStyle = {
    width: "25px",
    height: "25px",
  };
  const buttonStyle = isDeskTop
    ? {
        width: "160px",
        display: "flex",
        cursor: "pointer",
        gap: "15px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "9px",
        background: "white",
        height: "50px",
      }
    : {
        padding: "5px",
        fontWeight: "500",
        height: "55px",
        cursor: "pointer",
        display: "flex",
        width: "90px",
        flexDirection: "column",
        background: "white",
        border: "2px solid black",
        borderRadius: "9px",
      };
  const fontStyle = isDeskTop
    ? { fontFamily: "Inter", marginTop: "13px" }
    : {
        fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: "400",
      };

  return (
    <form onSubmit={handleSubmit} style={formInLineStyle}>
      <button disabled={!stripe} style={buttonStyle}>
        <img src="/icons/aliPay.png" style={buttonInlineStyle} />{" "}
        <p style={fontStyle}>Alipay</p>
      </button>
    </form>
  );
};

export default AliPay;
