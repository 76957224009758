import { amplitude } from "../utils/amplitude";
import { basePath } from "./constants";
import axios from "axios";

export const sendEvent = async (event) => {
  try {
    const PageLocation =
      (typeof window.document !== "undefined" && window.document.referrer) ||
      "";
    await axios
      .post(`${basePath}/fetch/document`, {
        pageLink: PageLocation,
      })
      .then((res) => {
        const response = res.data;
        const pageInformation = response.RESPONSE;

        const properties = {
          "[Amplitude] Page Domain": pageInformation.pageDomain,
          "[Amplitude] Page Location": pageInformation.pageLocation,
          "[Amplitude] Page Path": pageInformation.pagePath,
          "[Amplitude] Page Title": pageInformation.pageTitle,
          "[Amplitude] Page URL": pageInformation.pageURL,
        };

        console.log("event: ", event);
        amplitude.getInstance().logEvent(event, properties);
      })
      .catch((error) => {
        console.log("error", error);
      });
  } catch (err) {
    console.log("Error on log event.", err);
  }
};
