import useResponsiveFontSize from "./useResponsiveFontSize";
import { useMemo } from "react";

const usePaymentCardOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          fontWeight: 400,
          textAlign: "center",
          fontFamily: "Helvetica Neue",
          "::placeholder": {
            color: "#878686",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: 400,
            fontFamily: "Helvetica Neue",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [],
  );

  return options;
};

export default usePaymentCardOptions;
