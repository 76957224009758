import React from "react";
import { BottomCard } from "../components/BottomCard";
import { Outlet } from "react-router-dom";
import Overlay from "../components/overlay";

const GeneralLayout = () => {
  return (
    <div className='overlay'>
      <Overlay />
      <BottomCard>
        <Outlet />
        <div className='footerArea'>
          <div className='title'>
            <span>Acta</span> paywall experience
          </div>
          <div className='seprater'>|</div>
          <div className='links'>
            <a
              href='https://readwithacta.com/privacy_policy'
              target='_blank'
              rel='noreferrer'>
              Terms
            </a>
            <a
              href='https://readwithacta.com/terms'
              target='_blank'
              rel='noreferrer'>
              Privacy
            </a>
          </div>
        </div>
      </BottomCard>
    </div>
  );
};

export default GeneralLayout;
