import React, { useEffect, useState } from "react";
import { BottomCard } from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { adjustColorBrightness } from "../../utils/helper";
import { CustomButton } from "../button/index.style";
import { TEXTS } from "../../utils/text";

const SubscriptionCard = ({ setStep, publisherDetails }) => {
  const { userProfile } = useSelector((state) => state?.auth);

  const publisherButtonStyle = {
    background: publisherDetails?.primaryColor ?? "#4209EE",
    color: publisherDetails?.secondaryColor ?? "#ffffff",
  };

  return (
    <BottomCard>
      <div>
        <h3>To continue reading, choose an option below</h3>
      </div>
      <div>
        {/* <button
          style={{
            marginBottom:
              Object.keys(userProfile)?.length > 0 ? "0.75rem" : "0rem",
          }}
          onClick={() =>
            Object.keys(userProfile)?.length > 0
              ? setStep("paymentSuccess")
              : setStep("signIn")
          }
          className='subscribBtn'>
          Subscribe for $15 a month
        </button> */}

        <h4>{publisherDetails?.actaPromptText ?? TEXTS.DEFAULT_ACTA_PROMPT_TEXT}</h4>

        <PaymentButton
          hovercolor={publisherDetails?.primaryColor ?? "#4209EE"}
          onClick={() =>
            Object.keys(userProfile)?.length > 0
              ? setStep("paymentSuccess")
              : setStep("signIn")
          }
          style={publisherButtonStyle}>
          <div className='lockIcon'>
            <img src='/icons/lock.svg' alt='' />
            <div>
              {`${publisherDetails?.actaButtonText ?? TEXTS.DEFAULT_ACTA_BUTTON_TEXT} ${publisherDetails?.articlePrice}`}
            </div>
          </div>
        </PaymentButton>

        {Object.keys(userProfile)?.length > 0 ? null : (
          <p>
            Already have an account?{" "}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setStep("signIn")}>
              Sign in
            </span>
          </p>
        )}
        {/* <FormButton
            bgColor='#4209EE'
            textColor='#ffffff'
            btnTitle='Read it for $0.30'
            showPayment={showPaymentModal}
            setShowPayment={setShowPaymentModal}
          /> */}
      </div>
    </BottomCard>
  );
};

export default SubscriptionCard;

export const PaymentButton = styled(CustomButton)`
  &:hover {
    background: ${(prop) => {
      const darkenedColor = adjustColorBrightness(prop?.hovercolor, -20); // Darken the color by reducing brightness
      return darkenedColor;
    }} !important;
    box-shadow: 6px 6px 20px rgba(66, 9, 238, 0.25);
    border: none !important;
  }
`;
