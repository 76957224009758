import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
// import { toast } from "react-toastify";

// get Publisher Details
export const getPublisherDetails = createAsyncThunk(
  "publisher/getPublisherDetails",
  async ({ publisherId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPublisherDetails(publisherId, data);
      return response?.data?.RESPONSE;
    } catch (error) {
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  }
);

const initialState = {
  loading: false,
  publisherDetails: {},
};

const publisherSlice = createSlice({
  name: "publisher",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublisherDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPublisherDetails.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload)
        state.publisherDetails = {...state.publisherDetails,...action.payload}
      })
      .addCase(getPublisherDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default publisherSlice.reducer;
export const {} = publisherSlice.actions;
