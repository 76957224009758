import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { decode } from "js-base64";
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Specify weight

import { sendToPublisherWebsite } from "./handleRedirection";
import { getPublisherDetails } from "../../redux/publisherSlice";
import { PaymentType, ClientMode, PaymentMethod } from "./paymentCconfig";
import { useDesktopCheck } from "../../hooks/useDesktopCheck";
import { useCountryCode } from "../../hooks/useCountryCode";
import { useCheckCountryAllow } from "../../hooks/useCheckCountryAllow";
import { ApiRequests } from "../../services/ApiRequest";

const WechatPay = ({
  price,
  money,
  user,
  publisher,
  article,
  setStep,
  setIsScrolledDown,
  screen,
}) => {
  const [secret, setSecretInformation] = useState(null);
  const [interval, setPollingInterval] = useState(undefined);
  const dispatch = useDispatch();
  const publisherState = useSelector((state) => state.publisher);

  const code = useCountryCode();
  const countryCheckFlag = useCheckCountryAllow(code);

  const stripe = useStripe();
  const elements = useElements();
  const isDeskTop = useDesktopCheck();

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [interval]);

  if (!countryCheckFlag) return;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    let client_secret;
    if (!secret) {
      try {
        const tokenInfo = await ApiRequests.getPaymentIntentSecurityInfo({
          price,
          money,
          user,
          publisher,
          article: decode(article),
          method: PaymentMethod.wechat,
        });
        setSecretInformation(tokenInfo?.data?.data?.client_secret);
        client_secret = tokenInfo?.data?.data?.client_secret;
      } catch (error) {
        console.log(error.message);
      }
    } else {
      client_secret = secret;
    }

    if (!client_secret) return;

    const { error, paymentIntent } = await stripe.confirmWechatPayPayment(
      client_secret,
      {
        payment_method_options: {
          wechat_pay: {
            client: ClientMode.web,
          },
        },
      },
      { handleActions: false },
    );

    if (error) return;

    const simulateurl =
      paymentIntent?.next_action?.wechat_pay_display_qr_code?.data;

    const qrCodeImageDataUrl =
      paymentIntent?.next_action?.wechat_pay_display_qr_code?.image_data_url;
    sendToPublisherWebsite(PaymentType.wechat, {
      qrCodeImageDataUrl,
      simulateurl,
    });

    const intervalId = setInterval(async () => {
      try {
        await ApiRequests.transactionCheck({
          article: decode(article),
          user,
        });
        setStep("signIn");
        setIsScrolledDown("false");
        localStorage.setItem("payId:", user);
        setTimeout(() => {
          dispatch(
            getPublisherDetails({
              publisherId: publisher,
              data: { articleURL: decode(article), userId: user },
            }),
          );

          window.parent.postMessage(
            {
              purchased: publisherState?.publisherDetails?.purchased,
              postData: publisherState?.publisherDetails?.postData,
            },
            "*",
          );
        }, 500);
        clearInterval(intervalId);
      } catch (error) {
        console.log(error);
      }
    }, 1500);
    setPollingInterval(intervalId);
  };

  const formInLineStyle = isDeskTop
    ? {
        position: "absolute",
        left: "422px",
        top: screen !== "finalize" ? "205px" : "218px",
      }
    : {
        position: "absolute",
        left: "5%",
        top: screen !== "finalize" ? "310px" : "320px",
      };
  const buttonStyle = isDeskTop
    ? {
        width: "160px",
        display: "flex",
        cursor: "pointer",
        gap: "15px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "9px",
        background: "white",
        height: "50px",
      }
    : {
        fontWeight: "500",
        cursor: "pointer",
        height: "55px",
        display: "flex",
        width: "90px",
        padding: "5px",
        flexDirection: "column",
        background: "white",
        border: "2px solid black",
        borderRadius: "9px",
      };
  const buttonInlineStyle = { width: "25px", height: "25px" };
  const fontStyle = isDeskTop
    ? { fontFamily: "Inter", marginTop: "13px" }
    : {
        fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: "400",
      };

  return (
    <form onSubmit={handleSubmit} style={formInLineStyle}>
      <button disabled={!stripe} style={buttonStyle}>
        <img src="/icons/wechat.png" style={buttonInlineStyle} />
        <p style={fontStyle}>WeChat Pay</p>
      </button>
    </form>
  );
};

export default WechatPay;
