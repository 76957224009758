import styled from "styled-components";
import { PayBtn } from "../payment/index.style";

export const StripePayBtn = styled(PayBtn)`
  background: transparent;
  div {
    width: 100%;
  }
`;
export const PayWithCard = styled(PayBtn)`
  border-radius: 5px;
  height: 42px;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  display: flex;
  gap: 10px;
  img {
    height: 32px;
    width: 32px;
  }
  &:hover {
    color: #fff;
  }
`;
export const PaySection = styled.div`
  width: 220px;
  font-family: Catamaran;

  @media (max-width: 992px) {
    & {
      width: 170px;
    }
  }
  p {
    font-size: 15px;
    color: #000000;
    margin-top: 5px;
  }
`;
export const PayWithCardDisabled = styled(PayBtn)`
  border-radius: 5px;
  background: #838383;
  height: 42px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  cursor: not-allowed;
  opacity: 1;
  span {
    height: 50%;
    width: 2px;
    background: #fff;
  }
  img {
    height: 20px;
    width: 80px;
  }

  &:hover {
    background: #838383;
    color: #fff;
  }
`;
