import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { decode } from "js-base64";
import { Wrapper, SuccessPage } from "./index.style";
// import BottomSheetModal from "../../../components/bottomSheetModal";
import { useSelector, useDispatch } from "react-redux";
import { getExposeProfile, getUserProfile } from "../../../redux/authSlice";
import SubscriptionCard from "../../../components/subscriptionCard";
import SignupModal from "../../../components/signup";
import OTPModal from "../../../components/otpModal";
import PaymentSuccess from "../../../components/paymentSuccess";
import PaymentModal from "../../../components/payment";
import PaymentSignup from "../../../components/paymentSignup";
import StripeSkeleton from "../../../components/stripeSkeleton";
import { getPublisherDetails } from "../../../redux/publisherSlice";

const Home = () => {
  const [isScrolledDown, setIsScrolledDown] = useState(true);
  const [step, setStep] = useState("signIn");
  const [userPhoneNumber, setUserPhoneNumber] = useState(null);
  const [queryParameters] = useSearchParams();
  // const [step, setStep] = useState("paymentSuccess");

  const dispatch = useDispatch();
  const { id, url } = useParams();

  useEffect(() => {
    const user = localStorage.getItem("authUser");
    const token = JSON.parse(user)?.token ?? "";

    if (token) {
      dispatch(getUserProfile({ setStep }));
    } else {
      if (user && JSON.parse(user)?.id) {
        dispatch(getExposeProfile({ setStep }));
      }
    }
  }, [dispatch]);

  const { pagLoading, userProfile } = useSelector((state) => state.auth);

  useEffect(() => {
    const userInfo = localStorage.getItem("authUser");
    const userId = JSON.parse(userInfo)?.id ?? "";
    if (userId) {
      dispatch(
        getPublisherDetails({
          publisherId: id,
          data: { articleURL: decode(url), userId: userId },
        }),
      );
    } else {
      dispatch(
        getPublisherDetails({
          publisherId: id,
          data: { articleURL: decode(url) },
        }),
      );
    }
  }, [id, url, dispatch]);

  useEffect(() => {
    const userInfo = localStorage.getItem("authUser");
    const userId = JSON.parse(userInfo)?.id ?? "";
    if (step === "paymentSignup") {
      dispatch(
        getPublisherDetails({
          publisherId: id,
          data: { articleURL: decode(url), userId: userId },
        }),
      );
    }
  }, [step]);

  const { publisherDetails, loading } = useSelector(
    (state) => state?.publisher,
  );

  const user = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    if (step === "paymentSignup") return;
    if (publisherDetails.purchased === undefined) return;
    console.log(publisherDetails);
    if (publisherDetails?.purchased) {
      let baseURL = new URL(decode(url))?.origin;
      // post message to parent window
      const testurl = queryParameters.get("testurl");
      if (testurl) {
        baseURL = testurl;
      }

      if (user && user?.token) {
        const verifyOTPitem = localStorage.getItem("verifyotp");
        if (verifyOTPitem) {
          window.parent.postMessage(
            {
              purchased: publisherDetails?.purchased,
              postData: publisherDetails?.postData,
            },
            baseURL,
          );
        }
      } else if (user && !user?.token) {
        const payId = localStorage.getItem("payId:");
        if (payId) {
          window.parent.postMessage(
            {
              purchased: publisherDetails?.purchased,
              postData: publisherDetails?.postData,
            },
            baseURL,
          );
        }
      } else {
        //none
      }
    }
    if (user && user?.token) {
      const verifyOTPitem = localStorage.getItem("verifyotp");
      if (!verifyOTPitem) {
        setStep("paymentSignup");
      } else {
        if (Object.keys(user)?.length > 0 && !publisherDetails?.purchased) {
          setStep("paymentSuccess");
        } else {
          setIsScrolledDown(false);
        }
      }
    } else if (user && !user?.token) {
      if (Object.keys(user)?.length > 0 && !publisherDetails?.purchased) {
        if (user?.stripe?.customer_id && user?.stripe?.card) {
          const isFirst = localStorage.getItem("isFirst");
          if (isFirst) {
            setStep("paymentSuccess");
          } else {
            setStep("paymentSignup");
          }
        } else if (user?.stripe?.customer_id && !user?.stripe?.card) {
          const isFirst = localStorage.getItem("isFirst");
          if (isFirst) {
            setStep("paymentSuccess");
          } else {
            if (user.otpOn) {
              setStep("confirmOtp");
            } else {
              setStep("paymentSignup");
            }
          }
        } else if (user && user.email) {
          setStep("addPayment");
        }
      } else {
        const payId = localStorage.getItem("payId:");

        if (payId) {
          setIsScrolledDown(false);
        } else {
          setStep("paymentSignup");
        }
      }
    } else {
      //none
    }
  }, [publisherDetails, publisherDetails?.purchased, url, userProfile?.id]);

  if (pagLoading || loading) {
    return <StripeSkeleton />;
  }
  console.log(step);
  return (
    <Wrapper>
      {isScrolledDown ? (
        <>
          {step === "subscriptionCard" ? (
            <SubscriptionCard
              setStep={setStep}
              publisherDetails={publisherDetails}
            />
          ) : step === "signIn" ? (
            <SignupModal setStep={setStep} />
          ) : step === "confirmOtp" ? (
            <OTPModal
              setStep={setStep}
              setIsScrolledDown={setIsScrolledDown}
              userPhoneNumber={userPhoneNumber}
              setUserPhoneNumber={setUserPhoneNumber}
            />
          ) : step === "addPayment" ? (
            <PaymentModal
              setStep={setStep}
              setIsScrolledDown={setIsScrolledDown}
            />
          ) : step === "paymentSignup" ? (
            <PaymentSignup
              setStep={setStep}
              setIsScrolledDown={setIsScrolledDown}
              setUserPhoneNumber={setUserPhoneNumber}
            />
          ) : step === "paymentSuccess" ? (
            <PaymentSuccess
              setStep={setStep}
              setIsScrolledDown={setIsScrolledDown}
              publisherDetails={publisherDetails}
              articleURL={decode(url)}
            />
          ) : null}
        </>
      ) : (
        <SuccessPage>
          <svg className="tickIcon" viewBox="0 0 24 24">
            <path
              fillRule="evenodd"
              d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25z"
              clipRule="evenodd"></path>
          </svg>
          <h1>Payment Successful!</h1>
        </SuccessPage>
      )}
    </Wrapper>
  );
};

export default Home;
